<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline"
        >Добавление комментария к работе</v-card-title
      >
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            v-model="comment"
            label="Комментарий"
            textarea
            :rules="[(v) => !!v || 'Поле должно быть заполнено']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">Отменить</v-btn>
        <v-btn color="primary" dark @click.native="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['view'],
  components: {},
  data() {
    return {
      comment: '',
      errComment: false,
      dialog: true,
      valid: false,
    }
  },
  computed: {},
  mounted: function () {
    console.log(this.view)
  },
  methods: {
    save: function () {
      var t = this
      if (t.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'job/itemComment/', {
            id: this.view.id,
            job: this.view.job,
            comment: this.comment,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
