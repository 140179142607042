<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700px">
    <v-card>
      <v-card-title class="headline"
        >История изменений работы: <b>{{ view.name }}</b></v-card-title
      >
      <v-card-text>
        Данные по работе:<br />
        Заказ:{{ view.job_name }}<br />
        Статус:{{ view.status_name }}<br />
        Cтоимость:{{ view.cost }}<br />
        <v-list two-line subheader>
          <v-list-tile avatar v-for="data in datas" v-bind:key="data.id">
            <v-list-tile-avatar>
              <v-icon v-bind:class="[data.status_cls]">
                {{ data.icon }}
              </v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-if="data.tpe == 1">
                {{ data.status_name }} {{ data.item_name }}
              </v-list-tile-title>
              <v-list-tile-title v-if="data.tpe == 3">
                {{ data.comment }}
              </v-list-tile-title>
              <v-list-tile-title v-if="data.tpe != 1 && data.tpe != 3">
                {{ data.tpe_name }} {{ data.item_name }}
              </v-list-tile-title>
              <v-list-tile-sub-title>
                <v-icon>access_time</v-icon>
                {{ data.dte }}
                <v-icon>person</v-icon>
                {{ data.user_fio }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">Закрыть</v-btn>
        <v-btn color="success" dark @click="addComment">Добавить</v-btn>
      </v-card-actions>
    </v-card>
    <modal_comment
      v-if="modal_comment"
      v-bind:view="modalView"
      v-on:close="modalClose"
    ></modal_comment>
  </v-dialog>
</template>

<script>
import modal_comment from './item_comment.vue'
export default {
  props: ['view'],
  data() {
    return {
      datas: [],
      modal_comment: false,
      dialog: true,
    }
  },
  components: {
    modal_comment,
  },
  computed: {},

  mounted: function () {
    var t = this
    t.dataLoad()
  },
  methods: {
    dataLoad: function () {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/itemlog/' + this.view.id)
        .then(
          (response) => {
            t.datas = response.body
          },
          (err) => {
            console.log(err)
          }
        )
    },
    modalClose: function (prm) {
      this.modal_comment = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    showComment: function (data) {
      //            console.log(data);
      this.$set(data, 'show', !data.show)
      //            data.show=!data.show
    },
    addComment: function () {
      this.modal_comment = true
      this.modalView = this.view
    },
  },
}
</script>

<style></style>
