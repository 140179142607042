<template>
  <div>
    <v-toolbar>
      <h4>Мои работы</h4>
      <v-spacer></v-spacer>
      <v-text-field
        label="Найти"
        v-model="search"
        append-icon="search"
        @keyup="dataLoad(status)"
      ></v-text-field>
    </v-toolbar>
    <v-tabs fixed-tabs>
      <v-tab @click="dataLoad(1)">
        Новые
        <v-chip
          label
          color="primary"
          text-color="white"
          v-if="jobCnt['group1'] > 0"
        >
          {{ jobCnt['group1'] }}</v-chip
        >
      </v-tab>
      <v-tab @click="dataLoad(2)">
        В работе
        <v-chip
          label
          color="blue"
          text-color="white"
          v-if="jobCnt['group2'] > 0"
        >
          {{ jobCnt['group2'] }}</v-chip
        >
      </v-tab>
      <v-tab @click="dataLoad(3)">
        Ожидание
        <v-chip
          label
          color="blue"
          text-color="white"
          v-if="jobCnt['group3'] > 0"
        >
          {{ jobCnt['group3'] }}</v-chip
        >
      </v-tab>
      <v-tab @click="dataLoad(5)">
        Просроченные
        <v-chip
          label
          color="danger"
          text-color="white"
          v-if="jobCnt['group5'] > 0"
        >
          {{ jobCnt['group5'] }}</v-chip
        >
      </v-tab>
      <v-tab @click="dataLoad(4)">
        Готовы
        <v-chip
          label
          color="success"
          text-color="white"
          v-if="jobCnt['group4'] > 0"
        >
          {{ jobCnt['group4'] }}</v-chip
        >
      </v-tab>
      <v-tab @click="dataLoad(6)">
        Архив
        <v-chip
          label
          color="grey darken-3"
          text-color="white"
          v-if="jobCnt['group6'] > 0"
        >
          {{ jobCnt['group6'] }}</v-chip
        >
      </v-tab>
      <v-tabs-slider color="blue"></v-tabs-slider>
    </v-tabs>

    <v-container grid-list-md text-xs-center v-if="setting.value2 != 2">
      <v-layout row wrap>
        <v-flex xs6 sm4 md3 v-for="row in tableData" v-bind:key="row.id">
          <v-card v-bind:color="row.status_cls">
            <v-card-title>
              <b>{{ row.job_name }}</b
              ><v-spacer></v-spacer>
              <p class="red lighten-4">
                <b>{{ row.cost }} р.</b>
              </p>
            </v-card-title>
            <v-card-text class="white">
              <b>{{ row.dte_finish }}</b
              ><br />
              {{ row.name }}<br />
              {{ row.customer_name }} ({{ row.customer_phone }}) <br />
            </v-card-text>
            <v-card-actions class="white px-0">
              <v-btn fab small outline @click="showLog(row)"
                ><v-icon>message</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <span class="text-xs-right">
                <v-menu right>
                  <v-btn color="primary" dark slot="activator"
                    >{{ row.status_name
                    }}<i class="material-icons">arrow_drop_down</i></v-btn
                  >
                  <v-list>
                    <v-list-tile
                      v-for="status in statuss.filter((e) => e.group < 6)"
                      :key="status.id"
                      @click="selStatus(row, status)"
                    >
                      <v-list-tile-title>{{ status.name }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </span>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-data-table
      v-bind:headers="headers"
      :items="tableData"
      hide-actions
      class="elevation-1"
      v-if="setting.value2 == 2"
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td>{{ props.item.job_name }}</td>
          <td>
            {{ props.item.name }}
            <v-btn small @click="showLog(props.item)">Лог</v-btn>
          </td>
          <td class="text-xs-right">{{ props.item.customer_name }}</td>
          <td class="text-xs-right">{{ props.item.customer_phone }}</td>
          <td class="text-xs-right">{{ props.item.dte_finish }}</td>
          <td class="text-xs-right">
            <v-menu offset-y>
              <v-btn color="primary" dark slot="activator">{{
                props.item.status_name
              }}</v-btn>
              <v-list>
                <v-list-tile
                  v-for="status in statuss"
                  :key="status.id"
                  @click="selStatus(props.item, status)"
                >
                  <v-list-tile-title>{{ status.name }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>
          <td class="text-xs-right">{{ props.item.cost }}</td>
        </tr>
      </template>
    </v-data-table>

    <modal_log
      v-if="modal_log"
      v-bind:view="modalView"
      v-on:close="modalLogClose"
    ></modal_log>
  </div>
</template>

<script>
import modal_log from './item_log.vue'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Номер',
          align: 'left',
          value: 'job_name',
        },
        {
          text: 'Название',
          align: 'left',
          value: 'name',
        },
        { text: 'Клиент', value: 'customer_name' },
        { text: 'Телефон', value: 'customer_phone' },
        { text: 'Срок', value: 'dte_finish' },
        { text: 'Статус', value: 'status_name' },
        { text: 'Стоимость', value: 'cost' },
      ],
      search: '',
      tableLoad: false,
      tableData: ['load'],
      modal_log: '',
      modalTitle: '',
      modalView: [],
      jobCnt: [],
      status: 1,
    }
  },
  components: {
    modal_log,
  },
  computed: {
    statuss: function () {
      return this.$store.state.spr.status
    },
    setting: function () {
      console.log(this.$store.state.spr.setting)
      return this.$store.state.spr.setting
    },
  },
  mounted: function () {
    this.dataLoad(1)
    this.$store.dispatch('loadStatus')
    this.$store.dispatch('loadSetting')
  },
  methods: {
    modalClose: function (prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function (prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function (status) {
      var t = this
      if (status > 0) {
        this.status = status
      } else {
        status = this.status
      }

      this.$http
        .post(this.$store.state.apiUrl + 'job/item', {
          status: status,
        })
        .then(
          (response) => {
            if (response.body.err > 0) {
              t.tableData = ['error']
            } else {
              t.tableData = response.body.item
              t.jobCnt = response.body.cnt
              //                        console.log(response.body);
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    showJob: function (job) {
      var t = this
      if (job.listItems) {
        t.$delete(job, 'listItems')
        //                console.log(job.splice(1,1));
      } else {
        t.$set(job, 'loadItems', 1)
        this.$http.post(this.$store.state.apiUrl + 'job/load/' + job.id).then(
          (response) => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              t.$delete(job, 'loadItems')
              t.$set(job, 'listItems', response.body.item)
            }
          },
          (err) => {
            console.log(err)
          }
        )
      }
    },
    selStatus: function (item, status) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/ItemStatusSave/', {
          job: item.job,
          item: item.id,
          status: status.id,
        })
        .then(
          (response) => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              t.$set(item, 'status_name', status.name)
              t.$set(item, 'status', status.id)
              t.$set(item, 'status_cls', status.cls)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    showLog: function (view) {
      console.log(123)
      this.modal_log = true
      this.modalView = view
    },
    modalLogClose: function () {
      this.modal_log = ''
    },
  },
}
</script>

<style></style>
